import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import MakeHtml from '../components/commons/MakeHtml';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';

export function OperationnellePageTemplate({ title, cover, contents, introductionPage }) {
	return (
		<>
			<article>
				<ParallaxProvider>
					<ParallaxBanner
						className="your-class"
						layers={[
							{
								image: `${
									!!cover.image.childImageSharp
										? cover.image.childImageSharp.fluid.src
										: cover.image
								}`,
								amount: cover.parallaxSpeed,
								props: {
									style: { backgroundPosition: `${cover.positionnementImage}` },
								},
							},
						]}
						style={{
							height: '100%',
							padding: '80px 0',
						}}
					>
						{/* <div
					className="classical-full-img"
					style={{
						backgroundImage: `url(${
							!!cover.image.childImageSharp ? cover.image.childImageSharp.fluid.src : cover.image
						})`,
					}}
				> */}
						{introductionPage && introductionPage !== '' && (
							<div className="medium-contain classical-text-in-full-img-wrapper">
								<section className="classical-text-in-full-img">
									<h1>{title}</h1>
									<MakeHtml text={introductionPage} />
								</section>
							</div>
						)}
						{/* </div> */}
					</ParallaxBanner>
				</ParallaxProvider>

				{contents && (
					<section className="small-contain">
						{(!introductionPage || introductionPage === '') && <h1>{title}</h1>}
						{contents.map(({ contentImage, body, placement, style }, index) => (
							<div
								className={`activity-bloc-wrapper ${placement} ${style}`}
								key={`activity_bloc_${index}`}
							>
								{contentImage && contentImage.image && contentImage.altImage !== '' && (
									<picture>
										<source
											srcSet={
												!!contentImage.image.childImageSharp
													? contentImage.image.childImageSharp.fluid.srcWebp
													: contentImage.image
											}
											type="image/webp"
										/>
										<img
											src={
												!!contentImage.image.childImageSharp
													? contentImage.image.childImageSharp.fluid.src
													: contentImage.image
											}
											alt={contentImage.altImage}
											loading="lazy"
										/>
									</picture>
								)}

								{body && <MakeHtml text={body} />}
							</div>
						))}
					</section>
				)}
			</article>
			<style jsx>
				{`
					.classical-full-img {
						background-position: ${cover.positionnementImage};
					}
					.classical-text-in-full-img-wrapper {
						display: flex;
						justify-content: ${cover.positionnementBloc};
					}
				`}
			</style>
		</>
	);
}

export default function OperationnellePage({ data }) {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout title={frontmatter.seo.seoTitle} description={frontmatter.seo.seoDescription}>
			<OperationnellePageTemplate
				title={frontmatter.title}
				introductionPage={frontmatter.introductionPage}
				cover={frontmatter.cover}
				contents={frontmatter.contents}
				positionnementImage={frontmatter.positionnementImage}
			/>
		</Layout>
	);
}

export const pageQuery = graphql`
	query OperationnellePageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "l-excellence-operationnelle-page" } }) {
			frontmatter {
				title
				introductionPage
				cover {
					altImage
					image {
						childImageSharp {
							fluid(maxWidth: 1922, quality: 90) {
								...GatsbyImageSharpFluid
							}
						}
					}
					positionnementImage
					positionnementBloc
					parallaxSpeed
				}
				contents {
					body
					placement
					style
					contentImage {
						altImage
						image {
							childImageSharp {
								fluid(maxWidth: 1300, quality: 90) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
				seo {
					seoDescription
					seoTitle
				}
			}
		}
	}
`;
